import { useEffect, useState } from 'react';
import { TiposJuegos } from '../models/models';
import { generarCartaPoker, generarNumeroBinario, generarNumeroDecimal } from '../utils';

export const useJuegoRepaso = () => {
    const [loading, setLoading] = useState(false);
    const [autoplay, setAutoplay] = useState(false);
    const [tipoJuego, setTipoJuego] = useState("");
    const [indice, setIndice] = useState(-1);
    const [etapa, setEtapa] = useState(0);
    const [cantidad, setCantidad] = useState(1);
    const [nivel, setNivel] = useState(1);
    const [preguntas, setPreguntas] = useState<React.ReactNode[]>([]);

    const HandleTiempo = () => {
        if (etapa === 1) {
            setEtapa(2);
        } else {
            throw new Error("tiempo iniciado fuera de lugar");
        }
    }

    const iniciarJuego = () => {
        EncolarNuevaPreguntas();
        setEtapa(1);
    }

    const ConstruirPregunta = () => {
        let newPregunta: React.ReactNode;
        if (tipoJuego === TiposJuegos.cartas) {
            newPregunta = generarCartaPoker();
        } else if(tipoJuego === TiposJuegos.decimal){
            newPregunta = generarNumeroDecimal();
        }else {
            newPregunta = generarNumeroBinario();
        }
        return newPregunta
    }
    
    const EncolarNuevaPreguntas = () => {
        setPreguntas((prev) => [...prev, 
            <div>
                {Array.from({ length: cantidad }).map(() => (
                    <>
                        <div style={{
                            height: '10svh',
                            overflow: 'hidden',
                            display: 'flex',
                        }}>{ConstruirPregunta()}</div>
                        <br></br>
                    </>
                ))}
            </div>
        ]);
    }

    const HandleSiguiente = () => {
        EncolarNuevaPreguntas();
    }

    const HandleReiniciar = () => {
        setTipoJuego("")
        setPreguntas([]);
        setIndice(-1);
        setEtapa(0);
    }

    useEffect(() => {
        if (etapa === 1 && autoplay) {
          const intervalId = setInterval(() => {
            HandleSiguiente();
          }, ( 3 - ((nivel<=1?1:nivel) - 1) * 0.3) * 1000); // convertir segundos a milisegundos
    
          // Limpiar el intervalo al desmontar el componente o si cambia `etapa`
          return () => clearInterval(intervalId);
        }
      }, [etapa]);
    

    useEffect(() => {
      setIndice(preguntas.length - 1)
    }, [preguntas])

    useEffect(() => {
      if(tipoJuego !== ""){
        iniciarJuego()
      }
    }, [tipoJuego])
    
    return {
        loading,
        etapa,
        preguntas,
        indice,
        tipoJuego,
        autoplay,
        nivel,
        cantidad,
        setCantidad,
        setAutoplay,
        setTipoJuego,
        iniciarJuego,
        setNivel,
        HandleTiempo,
        HandleSiguiente,
        setEtapa,
        setPreguntas,
        setIndice,
        HandleReiniciar
    };
}