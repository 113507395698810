// /features/juegoFechas/hooks/useJuegoFechas.ts
import { useState } from 'react';
import { DiasSemana } from '../constants/diasSemana';
import { getRandomDateIn2024, obtenerDiaDeLaSemana } from '../utils';

export const useJuegoFechas = () => {
  const [limit2024, setLimit2024] = useState(false);
  const [loading, setLoading] = useState(false);
  const [etapa, setEtapa] = useState(0);
  const [preguntas, setPreguntas] = useState([]);
  const [indice, setIndice] = useState(-1);
  const [correctas, setCorrectas] = useState(0);
  const [tiempoMemoria, setTiempoMemoria] = useState(0);

  const iniciarJuego = (limit2024:boolean) => {
    CrearNuevaPregunta(limit2024);
    setLimit2024(limit2024)
    setEtapa(1);
  };

  const CrearNuevaPregunta = (limit2024:boolean) => {
    const fecha = getRandomDateIn2024(new Date(limit2024 ? 2024 : 1600,0,1),new Date(limit2024 ? 2024 : 2099,11,31));
    const nuevaPregunta = {
      fecha,
      opcionCorrecta: obtenerDiaDeLaSemana(fecha),
      inputUsuario: null,
    };
    setPreguntas([...preguntas, nuevaPregunta]);
    setIndice(indice + 1);
  };

  const handleSelectDia = (input: DiasSemana) => {
    let copyPreguntas = preguntas;
    copyPreguntas[indice].inputUsuario = input;
    setPreguntas(copyPreguntas);

    if (input === copyPreguntas[indice].opcionCorrecta) {
      setCorrectas(correctas + 1);
    }
    CrearNuevaPregunta(limit2024);
  };

  const HandleTiempo = () => {
    if (etapa === 1) {
        setEtapa(2);
    } else {
        throw new Error("tiempo iniciado fuera de lugar");
    }
}

  const reiniciarJuego = () => {
    setEtapa(0);
    setPreguntas([]);
    setIndice(-1);
    setCorrectas(0);
  };

  const resultadoFinal = correctas >= 5 ? '¡FELICITACIONES!' : '¡BUEN INTENTO!';

  return {
    etapa,
    preguntas,
    indice,
    loading,
    correctas,
    iniciarJuego,
    handleSelectDia,
    reiniciarJuego,
    resultadoFinal,
    setTiempoMemoria,
    HandleTiempo
  };
};
