import React, { useState } from 'react';

import styles from '../../../../../../../theme/Juegos.module.css';

import Loading from '../../../../../../components/Loading';
import TiempoWrapper from '../../../componentes/TiempoWrapper';
import TiempoJuego from '../juegoFechas/components/TiempoJuego';
import OpcionesRespuesta from './components/OpcionesRespuesta';
import Resultados from './components/Resultados';
import { useJuegoRepaso } from './hooks/useJuegoRepaso';
import SwitchButton from './components/Switch';
import Selector from '../../../../../../components/Selector';
import { TiposJuegos } from './models/models';
import { obtenerOpcionesDesdeEnum } from './utils';

const JuegoRepaso = () => {
    const { loading, etapa, preguntas, indice, tipoJuego, autoplay, nivel, cantidad, setCantidad, setAutoplay, setTipoJuego, setNivel, HandleTiempo, HandleSiguiente, HandleReiniciar } = useJuegoRepaso();

    return (
        <div id={'page'}>
            <Loading isOpen={loading}/>
            <div className={etapa === 0 ? styles.toolbarCalculoFecha : styles.toolbar_default}>
                {etapa === 0 && 
                    <SwitchButton isActive={autoplay} onToggle={setAutoplay} />
                }          
                <h1 className={`title ${styles.tituloJuegos}`} style={etapa !== 1 ? {fontSize:'2.37svh'} :{}}> Entrenamiento </h1>        
            </div>
            {etapa === 0 && 
                <div className={styles.contenedorIconoFecha}>
                    <img src='/assets/componentes/Juegos/SC.svg' className={styles.icono}></img>
                </div>
            }          
            <div id='content_default' style={{height:'90%',padding:'2%'}}>
                {/* Pantalla de inicio */}
                {etapa === 1 && <TiempoWrapper
                        handleTiempo={HandleTiempo}
                        maxTiempo={60}
                        setTiempoMemoria={(e:number) =>{}}
                        bandera={etapa === 1}
                        ascendente={false}
                        />}
                {etapa === 0 && 
                    <div className={styles.primeraPantalla}>
                        <div className={[styles.descripcionJuego,styles.descripcionMemoriaNumeros].join(" ")}>
                            Tienes un minuto para responder la mayor cantidad de fechas que puedas.
                        </div>
                        <Selector setSelection={setNivel} selection={nivel} opciones={Array.from({ length: 10 }, (_, i) => ({ value: i, name: `Nivel ${i}` }))}></Selector>
                        <Selector setSelection={setCantidad} selection={cantidad} opciones={Array.from({ length: 3 }, (_, i) => ({ value: i+1, name: `Cantidad ${i+1}` }))}></Selector>
                        <Selector setSelection={setTipoJuego} selection={tipoJuego} opciones={obtenerOpcionesDesdeEnum(TiposJuegos)}></Selector>
                    </div>}
                
                {/* Pantalla de preguntas */}
                {etapa === 1 && indice >= 0 && (
                <TiempoJuego>
                    <div className={styles.pregunta} style={{textAlign:'center',marginBottom:'7.23%',width:'fit-content',fontSize:'3.5em'}}>
                        {preguntas[indice]}
                    </div>
                    {!tipoJuego && <OpcionesRespuesta pregunta={preguntas[indice]}></OpcionesRespuesta>}
                    {!autoplay && <button className={styles.buttonEmpezar} onClick={HandleSiguiente}>
                        SIGUIENTE
                    </button>}
                </TiempoJuego>
                )}

                {/* Pantalla de resultados */}
                {etapa === 2 && 
                    <Resultados total={preguntas.length * cantidad} reiniciar={HandleReiniciar}></Resultados>
                }
            </div>
        </div>
    )
}

export default JuegoRepaso