// /features/juegoFechas/JuegoFechas.tsx
import React from 'react';
import styles from '../../../../../../../theme/Juegos.module.css';
import styleFecha from './style/JuegoFechas.module.css';
import { useJuegoFechas } from './hooks/useJuegoFechas';
import BotonDiasSemana from './components/BotonDiasSemana';
import Resultado from './components/Resultado';
import TiempoJuego from './components/TiempoJuego';
import { DiasSemana } from './constants/diasSemana';
import TiempoWrapper from '../../../componentes/TiempoWrapper';
import Loading from '../../../../../../components/Loading';

const JuegoFechas = () => {
  const {
    etapa,
    preguntas,
    indice,
    loading,
    correctas,
    iniciarJuego,
    handleSelectDia,
    reiniciarJuego,
    resultadoFinal,
    setTiempoMemoria,
    HandleTiempo
  } = useJuegoFechas();

  return (
    <div id={'page'}>
      <Loading isOpen={loading}/>
        <div className={etapa === 0 ? styles.toolbarCalculoFecha : styles.toolbar_default}>
            <h1 className={`title ${styles.tituloJuegos}`} style={etapa !== 1 ? {fontSize:'2.37svh'} :{}}> Calculo Fecha </h1>        
        </div>
        {etapa === 0 && 
            <div className={styles.contenedorIconoFecha}>
                <img src='/assets/componentes/Juegos/SC.svg' className={styles.icono}></img>
            </div>
        }          
      <div id='content_default' style={{height:'90%',padding:'2%'}}>
        {/* Pantalla de inicio */}
        {etapa === 1 && <TiempoWrapper
                  handleTiempo={HandleTiempo}
                  maxTiempo={60}
                  setTiempoMemoria={setTiempoMemoria}
                  bandera={etapa === 1}
                  ascendente={false}
                  />}
        {etapa === 0 && 
            <div className={styles.primeraPantalla}>
                <div className={[styles.descripcionJuego,styles.descripcionMemoriaNumeros].join(" ")}>
                    Tienes un minuto para responder la mayor cantidad de fechas que puedas.
                </div>
                <button className={styles.buttonEmpezar} onClick={() => iniciarJuego(true)}>
                  2024
                </button>
                <button className={styles.buttonEmpezar} onClick={() => iniciarJuego(false)}>
                  TODOS LOS AÑOS
                </button>
            </div>}
        
        {/* Pantalla de preguntas */}
        {etapa === 1 && indice >= 0 && (
          <TiempoJuego>
            <div className={styles.pregunta} style={{textAlign:'center',marginBottom:'7.23%',width:'fit-content',fontSize:'3.5em'}}>
              {preguntas[indice].fecha.toLocaleDateString('es', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })}
            </div>
            <div className={`${styleFecha.containButtons}${styles.contenedorRespuestas}`}
            style={{padding:'0',display:'flex',flexDirection:'row',flexWrap:'wrap',gap:'2em',justifyContent:'center',width:'105%'}}>
              {Object.values(DiasSemana)
                .filter(v => typeof v === 'number')
                .map((dia, idx) => (
                  <BotonDiasSemana 
                    key={idx} 
                    onClick={() => handleSelectDia(dia as DiasSemana)}
                    dia={dia as DiasSemana} 
                  />
                ))}
            </div>
          </TiempoJuego>
        )}

        {/* Pantalla de resultados */}
        {etapa === 2 && (
          <Resultado 
            correctas={correctas} 
            total={preguntas.length} 
            reiniciar={reiniciarJuego} 
            resultadoFinal={resultadoFinal} 
          />
        )}
      </div>
    </div>
  );
};

export default JuegoFechas;
