import React, { ReactNode, useEffect, useState } from 'react'
import { convertirBinarioADecimal } from '../utils'

const OpcionesRespuesta = ({pregunta}:{pregunta:ReactNode}) => {
    const [visible, setVisible] = useState(false)

    useEffect(() => {
     setVisible(false) 
    },[pregunta])
    
    return (
        <div style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            margin: '5%',
        }}>
            <h3 style={{background:visible?'':'black',color:'black',fontSize:'2em',margin:'auto'}}>{convertirBinarioADecimal(pregunta)}</h3>
            <button onClick={()=>setVisible(!visible)}>{!visible ? 'View' : 'Not'}</button>
        </div>
    )
}

export default OpcionesRespuesta