import style from "../../../../../theme/codigoPalabras.module.css";
import estiloJuegos from "../../../../../theme/Juegos.module.css";
import styleMemoriaNombres from "../../../../../theme/MemoriaNombres.module.css";
import style2 from "../../../../../theme/Juego2.module.css";
import styleRecorrerValores from "../../../../../theme/programas/cuestionarios/recorrerValores.module.css";

import { useJuegoCodigodePalabrasHooks } from './hooks/useJuegoCodigodePalabrasHooks'; 
import { useLogicaJuegoCodigoPalabras } from "./utils/utilsCodigoPalabras";
import { useEffect } from "react";
import CodImagenes from "../../../../../datos/DatosCodigoPalabras";
import { changeCondicion } from "../../../../../redux/actividad/reducerActividad";
import BotonBordeDegradado from "../../../../components/BotonBordeDegradado";
import BotonesJuegos from "../../../../components/BotonesJuegos";
import Loading from "../../../../components/Loading";
import MostrarResultados from "../../../rutasPrivadasMy/juegos/componentes/MostrarResultados";



const JuegoCodigodePalabrasPage = () => {
  const {
    preguntas,
    loading,
    userId,
    etapa,
    index,
    esEntrenamiento,
    seleccionada,
    openDescripcion,
    parametro,
    correctas,
    objetoDescripcion,
    tipoRespuesta,
    tipoPregunta,
    tipoPreguntaRedux,
    emailUsuario,
    indiceSesion,
    indiceActividad,
    lenghtActividades,
    idEntrenamiento,
    actualizarRegistroEntrenamiento,
    dispatch,
    navigate,
    setIndex,
    setEtapa,
    setCorrectas,
    setOpenDescripcion,
    setPreguntas,
    setSeleccionada,
    setTipoPregunta,
    setTipoRespuesta
  } = useJuegoCodigodePalabrasHooks(); // Usamos los hooks personalizados

  const {
    Descripcion,
    ScrollLeft,
    ScrollRight,
    HandleClick: HandleClickLogic,
    VerificarRespuesta,
    GenerarArrayImages,
    GenerarArrayResultadosAMostrar,
    GenerarArrayCalificaciones,
    SetPreguntasRandom,
  } = useLogicaJuegoCodigoPalabras()

  useEffect(() => {
    Constructor()
  }, [])

  const HandleFinalizar = () => {
    setEtapa(2);
    setCorrectas(VerificarRespuesta(preguntas));
  };

  const Constructor = () => {
    setEtapa(0)
    setTipoPregunta(tipoPreguntaRedux ?? 'imagen');
    setTipoRespuesta((tipoPreguntaRedux ?? 'imagen') === 'imagen' ? 'string' : 'imagen');
    setPreguntas([]);
    setSeleccionada([]);
    let newPreguntas = SetPreguntasRandom();
    setPreguntas(newPreguntas)
    setIndex(0);
  }

  const HandleBackButtonClick = async() => {
    if(!userId){
      return Constructor()
    }
    if(correctas>=parametro){
      actualizarRegistroEntrenamiento(emailUsuario,userId,idEntrenamiento,indiceSesion+1,indiceActividad+1);
      dispatch(changeCondicion({condicion:correctas}))
      navigate(lenghtActividades === 1 ? `/my/entrenamiento/${idEntrenamiento}` : `/my/entrenamiento/${idEntrenamiento}/${indiceSesion}`);
    }else{
      return navigate(-1);
    }
  }

  const HandleClick = (object:any,indexOpcion:number) => {
    let nuevaSeleccionada = HandleClickLogic(preguntas,index,object,indexOpcion)
    console.log("🚀 ~ HandleClick ~ nuevaSeleccionada:", nuevaSeleccionada)
    setSeleccionada(nuevaSeleccionada);
    setTimeout(() => {
      return Next();
    }, 500);
  }

  const Next = () => {
    console.log("🚀 ~ Next ~ ScrollRight(:", ScrollRight(index,preguntas))
    if(ScrollRight(index,preguntas) === true){
      setIndex(index +1)
    }
  }

  const Back = () => {
    if(ScrollLeft(index)){
      setIndex(index - 1)
    }
  }

  const GetCodigo = () => {
    if (tipoPregunta === "string"){
      return( 
        <>
          {preguntas.map((pregunta,index) => (
            <div className={style.scroll} key={index}>{pregunta?.codigo}</div>
          ))}
        </>
      );
    }else {
      return(
        <>
        {preguntas.map((pregunta,_index) => (
          <img className={`${style.scroll} ${style.imagen}`} key={pregunta?.codigo} id={`ficha${_index}`} src={`assets/images/fichas/_${pregunta.codigo}.png`}/>
        ))}
        </>
      );
    }
  }

  const GetOpciones = () => {
    if (tipoRespuesta === "string"){
      return( 
        <>
          { seleccionada[index] !==1  ? 
          <BotonBordeDegradado onClick={() => HandleClick(preguntas[index].opcion1,1)}>
            {preguntas[index].opcion1 > 99 ? `0${preguntas[index].opcion1 - 100}` : preguntas[index].opcion1}
          </BotonBordeDegradado>: 
          <button className={`${styleMemoriaNombres.inputsOptions} ${styleMemoriaNombres.selected}`}>
            <label style={{color:'white'}}>{preguntas[index].opcion1 > 99 ? `0${preguntas[index].opcion1 - 100}` : preguntas[index].opcion1}</label>
          </button>
          }
          {seleccionada[index] !== 2 ?
          <BotonBordeDegradado onClick={() => HandleClick(preguntas[index].opcion2,2)}>
            {preguntas[index].opcion2 > 99 ? `0${preguntas[index].opcion2 - 100}` : preguntas[index].opcion2}
          </BotonBordeDegradado>: 
          <button className={`${styleMemoriaNombres.inputsOptions} ${styleMemoriaNombres.selected}`}>
            <label style={{color:'white'}}>{preguntas[index].opcion2 > 99 ? `0${preguntas[index].opcion2 - 100}` : preguntas[index].opcion2}</label>
          </button>}
          {seleccionada[index] !== 3 ?
          <BotonBordeDegradado onClick={() => HandleClick(preguntas[index].opcion3,3)}>
            {preguntas[index].opcion3 > 99 ? `0${preguntas[index].opcion3 - 100}` : preguntas[index].opcion3}
          </BotonBordeDegradado>: 
          <button className={`${styleMemoriaNombres.inputsOptions} ${styleMemoriaNombres.selected}`}>
            <label style={{color:'white'}}>{preguntas[index].opcion3 > 99 ? `0${preguntas[index].opcion3 - 100}` : preguntas[index].opcion3}</label>
          </button>}
        </>
      );
    }else {
      return(
        <>
          <button className={`${seleccionada[index]===1 ? style.seleccionada :""}`} onClick={() => HandleClick(preguntas[index].opcion1,1)}>
            <div className={`${seleccionada[index]===1 ? style.transparente  :""}`}>
              <img className={style.imagenOpciones} src={`assets/images/fichas/_${preguntas[index].opcion1}.png`}/>
            </div>
          </button>
          <button className={`${seleccionada[index]===2 ? style.seleccionada :""}`} onClick={() => HandleClick(preguntas[index].opcion2,2)}>
            <div className={`${seleccionada[index]===2 ? style.transparente  :""}`}> 
            <img className={style.imagenOpciones} src={`assets/images/fichas/_${preguntas[index].opcion2}.png`}/>
            </div>
          </button>
          <button className={`${seleccionada[index]===3 ? style.seleccionada :""}`} onClick={() => HandleClick(preguntas[index].opcion3,3)}>
            <div className={`${seleccionada[index]===3 ? style.transparente :""}`}>
              <img className={style.imagenOpciones} src={`assets/images/fichas/_${preguntas[index].opcion3}.png`}/>
            </div>
          </button>
        </>
      );
    }
  }

  return (
    <div id='page'>
      <Loading isOpen={loading}/>
      <div id={etapa !== 0 && 'toolbar_default'} className={etapa === 0 ? estiloJuegos.toolbarMemoriaPalabras : style.toolbar_default}>
        {userId && <div className={estiloJuegos.backJuegos} onClick={HandleBackButtonClick}/>}
        <h1 className="title" style={{marginTop:'2svh'}}>Código de Palabras </h1> 
      </div>
        {etapa === 0 && 
          <div className={estiloJuegos.contenedorIconoPalabras}>
            <img src='/assets/componentes/Juegos/AC5.svg' className={estiloJuegos.icono}></img>
          </div>
        }
      <div id='content_default' style={{padding:'2%'}}>
        {etapa === 0 && <>
          <div className={estiloJuegos.primeraPantalla} style={{justifyContent:'space-evenly'}}>
            <div className={estiloJuegos.descripcionJuego}>
              ¡Desafía tu memoria! <br></br> Tienes encontrar el valor de las siguientes 10 palabras.
            </div>
            <button className={estiloJuegos.buttonEmpezar} onClick={()=>setEtapa(1)}>
              EMPEZAR
            </button>
          </div>
        </>}
        {etapa === 1 &&
          <div className={style.contenedor}>
          <div className={style.navegacionImagen}>
            <div className={style.contenedorCodigo} >
              {tipoPregunta === "imagen" && 
                <img src='/assets/icon/helpCirculo.svg' onClick={() =>Descripcion(preguntas[index].codigo)} className={style.buttonDescription}/>
              }
              <div className={style.contenedorScroll} >
                {GetCodigo()}
              </div>
            </div>
          </div>
             <div className='centrar' style={{gap:'10px',margin:'2svh 0'}}>
               <p className='subtitleHome' style={{textTransform:'uppercase',fontSize:'5svh'}}>{CodImagenes.getName(preguntas[index]?.codigo)}</p>
               <img src='/assets/icon/helpCirculo.svg' onClick={() =>Descripcion(preguntas[index]?.codigo)}/>
             </div>
             <div className={style.contenedorOpciones}>
               {GetOpciones()}
             </div>
             {tipoRespuesta === "imagen" && <div className={style.contenedorDescripciones}>
                 <img src='/assets/icon/helpCirculo.svg' onClick={() =>Descripcion(preguntas[index]?.opcion1)}/>
                 <img src='/assets/icon/helpCirculo.svg' onClick={() =>Descripcion(preguntas[index]?.opcion2)}/>
                 <img src='/assets/icon/helpCirculo.svg' onClick={() =>Descripcion(preguntas[index]?.opcion3)}/>
             </div>}
             <div style={{display:'flex',justifyContent:'center',gap: '0.55svh'}}>
                 <button className={`${style2.nv_button} ${!index ? 'element_block':''}`} onClick={Back}>
                   <img src='/assets/icon/flechaBlancoSend.svg' alt='Go' style={{transform:'rotate(180deg)'}}></img>
                 </button>
                 {index < 9 ? 
                 <button className={style2.nv_button} onClick={Next}>
                     <img src='/assets/icon/flechaBlancoSend.svg' alt='Go'></img>
                 </button> 
                 : <button className={style2.nv_button} style={{width:'24.2svh',marginLeft:'14.24px'}} onClick={HandleFinalizar}>TERMINAR</button>}
             </div>
          </div>
        }
        {etapa === 2 &&
          <>
            <div className={style2.contenido} style={{ padding: `0 0 ${correctas<=0? "2%": ""}`, display: "flex", flexDirection: "column", fontFamily: 'Montserrat'}}>
                <p className={`${correctas >=7 ?
                  styleRecorrerValores.resultado2 : correctas >=5 ?
                  styleRecorrerValores.resultado1 : styleRecorrerValores.resultado0
                } ${styleRecorrerValores.mensajePersonalizado}`} style={{fontSize:'3.64svh'}}>
                  { correctas>=parametro ? '¡FELICITACIONES!' : '¡BUEN INTENTO!'} 
                </p>
                <p className={styleRecorrerValores.mensajePersonalizado} 
                style={{ color: 'var(--color-Medium-Grey)',fontSize:'2.5svh' }}>
                  {correctas >= parametro ? (
                    'PUEDES AVANZAR A LA SIGUIENTE ACTIVIDAD'
                  ) : (
                    <>
                      OBTÉN MINIMO <span style={{ color: 'var(--desired-color)' }}>{parametro}</span> ACIERTOS PARA AVANZAR
                    </>
                  )}
                </p>
                <MostrarResultados 
                  arrayItemImg={GenerarArrayImages(preguntas)}
                  arrayAMostrar={GenerarArrayResultadosAMostrar(preguntas)} 
                  calificaciones={GenerarArrayCalificaciones(preguntas)}
                  styleContenedorTabla={{maxHeight:'50svh',margin:'4% 2%'}}
                />
                <div style={{margin: "0svh auto"}}>
                  <p className={style2.textoTuviste}>TUVISTE</p>
                  <p className={style2.resultado} style={{margin:'0'}}>{`${correctas}/${10}`}</p>
                </div>
            </div>
            {!userId ? <button id='button_rosado_default' style={{width:'fit-content'}} onClick={HandleBackButtonClick}>Jugar de nuevo</button> :
            <>
              {(parametro!==0 && correctas>=parametro) ? <button id='button_rosado_default' className={style.finalizar} onClick={HandleBackButtonClick}>Volver</button>:
              <BotonesJuegos funcionReiniciar={Constructor} funcionVolver={HandleBackButtonClick} esJuego={false} esEntrenamiento={esEntrenamiento}/>}
              <div className={style.contenido} style={{ padding: `0 0 ${correctas<=0? "5%": ""}`, height: "85%", flexGrow: 1, display: "flex", flexDirection: "column"}}></div>
            </>}
          </>
        }
        {openDescripcion &&
          <div id="modal_cortina_default"  onClick={() => setOpenDescripcion(false)}>
            <div id="modal_default" className={`${style.modal} scroll-up`} onClick={(e) => e.stopPropagation()} >
              <img className={style.back} src="assets/icon/flechaBlancaMorado.svg" alt="" onClick={() => setOpenDescripcion(false)} />
              <img src={`assets/images/fichas/${objetoDescripcion}.png`} alt="" style={{maxHeight:'54%'}}/>
              <p className={style.tituloImagen}> {CodImagenes.getName(objetoDescripcion)}</p>
              <p style={{textTransform:'none'}}>{CodImagenes.getDefinition(objetoDescripcion)}</p>
            </div>
          </div>
        }  
      </div>
    </div>
  );
};

export default JuegoCodigodePalabrasPage;
