import { useEffect, useRef, useState } from 'react';


import "../../../../../../theme/entrenamiento/entrenamiento.css";
import stylePopover from "../../../../../../theme/PopoverNeuronas.module.css";
import estiloJuegos from "../../../../../../theme/Juegos.module.css";
import estiloLanding from "../../theme/landing.module.css";

import { Timestamp } from 'firebase/firestore';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Checkout from '../../../../../../Checkout';
import { pageViewFacebook, agregarAlCarroFacebook } from '../../../../../../ConfigPixel';
import { paises } from '../../../../../../datos/paises';
import { useToast } from '../../../../../../generalUtils/useToast';
import { Campaña } from '../../../../../../modelos/entrenamiento/Campaña';
import { Factura, ProductoReferencia } from '../../../../../../modelos/Facturas';
import { useEntrenamiento } from '../../../../../../repositorio/controlador/useEntrenamiento';
import { useFacturas } from '../../../../../../repositorio/controlador/useFacturas';
import Loading from '../../../../../components/Loading';
import Selector from '../../../../../components/Selector';
import { Entrenamiento } from '../../models/Entrenamiento';
import VideoConContador from '../components/VideoConContador';
import Modal from '../../../../../components/Modal';
import ComponenteCortesia from '../components/ComponenteCortesia';

type Params = {
  titleEntrenamiento:string;
  codigoParams:string;
}

interface TiempoRestante {
  dias: number;
  horas: number;
  minutos: number;
  segundos: number;
}
interface Props{
  ruta?: string;
}

const LandingCurso = ({ruta}: Props) => {
    const { getFactura } = useFacturas();

    // INFORMACIÓN WEB
    const costo:number = 69000;

    const entorno = (process.env.VITE_APP_ENVIRONMENT === 'production')? "produccion": "desarrollo";

    const {titleEntrenamiento,codigoParams} = useParams<Params>();
    const [searchParams] = useSearchParams();
    const [entrenamiento,setEntrenamiento] = useState<Entrenamiento>();
    const [campaña,setCampaña] = useState<Campaña>();
    const [videoUrl1] = useState<string>(`https://firebasestorage.googleapis.com/v0/b/supercerebros-${entorno}.appspot.com/o/publico%2Fneurobics%2Fventa_neurobics.mp4?alt=media`);
    const [videoUrl2] = useState<string>(`https://firebasestorage.googleapis.com/v0/b/supercerebros-${entorno}.appspot.com/o/publico%2Fneurobics%2Ftestimonio1.mp4?alt=media`);
    const [videoUrl3] = useState<string>(`https://firebasestorage.googleapis.com/v0/b/supercerebros-${entorno}.appspot.com/o/publico%2Fneurobics%2FCORTESIA_NEUROBICS.webm?alt=media`);
    const [loading, setLoading] = useState(false);
    const [mensaje, setMensaje] = useState(false);
    const [openPopover,setOpenPopover] = useState(false);
    const [aplicaDescuento,setAplicaDescuento] = useState(false);
    const [showInfo,setShowInfo] = useState(false);
    const [montar,setMontar] = useState(false);
    const [descuento,setDescuento] = useState<number>(0);
    const [tiempoRestante,setTiempoRestante] = useState<TiempoRestante>();
    const [fechalimite,setFechalimite] = useState<Date>();
    const [textSize,setTextSize] = useState<number>(16);
    const [telefono,setTelefono] = useState<string>("");
    const [codigoTel,setCodigoTel] = useState<string>('+57');
    const [correo_usuario,setCorreo_usuario] = useState<string>("");
    const [nombre_usuairo,setNombre_usuairo] = useState<string>("");
    const [inputCodigoCampaña, setInputCodigoCampaña] = useState<string>("");

    const [facturaPagoDirecto, setFacturaPagoDirecto] = useState<{id:string, factura:Factura} | null>(null);

    
    const flecha1 = useRef<HTMLImageElement>(null);
    const flecha2 = useRef<HTMLImageElement>(null);
    const flecha3 = useRef<HTMLImageElement>(null);
    const flecha4 = useRef<HTMLImageElement>(null);
    const flecha5 = useRef<HTMLImageElement>(null);
    const flecha6 = useRef<HTMLImageElement>(null);
    const parrafo1 = useRef<HTMLDivElement>(null);
    const parrafo2 = useRef<HTMLDivElement>(null);
    const parrafo3 = useRef<HTMLDivElement>(null);
    const parrafo4 = useRef<HTMLDivElement>(null);
    const parrafo5 = useRef<HTMLDivElement>(null);
    const parrafo6 = useRef<HTMLDivElement>(null);

    const inputInscripcion = useRef<HTMLDivElement>(null);
    const ionPage = useRef<HTMLDivElement>(null);
    const referenceReproductor = useRef<any>(null);
    const video = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const {
      consultarCampaña,
      incrementarVisitas,
      consultarEntrenamientoVentas,
    } = useEntrenamiento();
    const {
      presentMensajePersonalizado
    } = useToast();  
    
    
    useEffect(() => {
        // Llamada a la función para incrementar las visitas
        console.log(`Incrementando visitas de ${codigoParams}`)
        console.log("titleEntrenamiento", titleEntrenamiento);
        // console.log(dato?.state);
        // console.log(email);
    }, []);
    
    
    function restarUnSegundoAlTiempo(fecha: Date):void {
      const ahora = new Date();
      
      // Obtener la diferencia en milisegundos
      let diferencia = Math.max(fecha.getTime() - ahora.getTime(), 0);
      if( diferencia <= 0){
        setAplicaDescuento(false)
        setDescuento(0)
        return setTiempoRestante({dias:0,horas:0,minutos:0,segundos:0} as TiempoRestante)
      }
    
      // Calcular días, horas, minutos y segundos
      const segundos = Math.floor(diferencia / 1000);
      const minutos = Math.floor(segundos / 60);
      const horas = Math.floor(minutos / 60);
      const dias = Math.floor(horas / 24);
    
      return setTiempoRestante({
        dias: dias,
        horas: horas % 24,
        minutos: minutos % 60,
        segundos: segundos % 60
      } as TiempoRestante);
    }

    const calcularTiempoRestante = async (timestamp: Timestamp) => {
      // Obtener la fecha actual
      const ahora = new Date();
    
      // Obtener la fecha objetivo a partir del Timestamp de Firebase
      const fechaObjetivo = timestamp?.toDate();
    
      // Calcular la diferencia en milisegundos
      const diferencia = fechaObjetivo?.getTime() - ahora.getTime();
      
      if (diferencia < 0) {
        setAplicaDescuento(false)
        setDescuento(0)
        return setTiempoRestante({dias:0,horas:0,minutos:0,segundos:0} as TiempoRestante)
      }
      
    
      // Calcular días, horas, minutos y segundos
      const dias = Math.floor(diferencia / (1000 * 60 * 60 * 24));
      const horas = Math.floor((diferencia % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutos = Math.floor((diferencia % (1000 * 60 * 60)) / (1000 * 60));
      const segundos = Math.floor((diferencia % (1000 * 60)) / 1000);

      return setTiempoRestante({dias:dias,horas:horas,minutos:minutos,segundos:segundos} as TiempoRestante)
    }
    
    useEffect(() => {
      let intervalId: any;
      
      if (aplicaDescuento) {
        intervalId = setInterval(() => {
          ajustarTexto()
          if(campaña?.fechaVigente !== undefined){
            calcularTiempoRestante(campaña?.fechaVigente)
          }else{
            if(fechalimite) restarUnSegundoAlTiempo(fechalimite)
          }}, 1000);
      }
      
      return () => clearInterval(intervalId);
    }, [aplicaDescuento]);

    useEffect(() => {
      let intervalId2: string | number | NodeJS.Timeout | undefined;
      
      if (showInfo === false) {
        intervalId2 = setInterval(() => {
          if(referenceReproductor.current){
            if(referenceReproductor.current.capturarInfoVideo()> 1){
              setShowInfo(true)
            }
          }
        },1000)
      }else if(searchParams.get("info") === "true"){
        ScrollToComprar();
      }
      
      return () => clearInterval(intervalId2);
    }, [showInfo]);


    const ajustarTexto = () => {
      if (ionPage.current) setTextSize(ionPage.current.offsetWidth * 0.032)
    }

  const CodigoInfo = async (codig: string) => {
    const codificado = await consultarCampaña(codig);

    console.log("codificado", codificado);
    

    if(!codificado || !(codificado.descuento)){
      
      setDescuento(0);
      setAplicaDescuento(false);
      return;
    }

    incrementarVisitas(codig).catch(console.error);

    let descuento = Number(codificado?.descuento);
    setDescuento(descuento);
    setCampaña(codificado);
    if(codificado?.fechaVigente !== undefined){
      calcularTiempoRestante(codificado?.fechaVigente)
    }else{
      if(codificado?.tiempoVigencia !== 0){
        let fechalimite = new Date();
        fechalimite.setMinutes(fechalimite.getMinutes() + (codificado?.tiempoVigencia ?? 0));
        setFechalimite(fechalimite)
        restarUnSegundoAlTiempo(fechalimite)
      }
    }
    setAplicaDescuento(true)
  }
    
  const Consulta = async (codig?:string) => {
    var divParaRutaEspecifica = document.getElementById("supercerebro-fondo");
    if(divParaRutaEspecifica) divParaRutaEspecifica.style.display = "block";
    console.log(codig);
    if(codig){
      await CodigoInfo(codig);
    }else{
      setAplicaDescuento(false)
    }
  }

  const ValidarEmail = (valor:string):Boolean => {
    var reg:RegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    var regOficial:RegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    if (reg.test(valor) && regOficial.test(valor)) {
      console.log("válido oficial y extraoficialmente");
      return true
    } else if (reg.test(valor)) {
      console.log("válido extraoficialmente");
      return true
      
    } else {
      console.log("incorrecto");
      return false
    }
  }
    

  const DesmontarPopover = () =>{
    setMontar(false)
    setTimeout(() => {
      setOpenPopover(false);
    }, 1000);
  }
  
  const CambiarLoading = () =>{
    setLoading(prev => !prev);
  }

  const CargaCompleta = (completo: boolean, info: boolean) => {
    if(completo){
      setLoading(false);
      setShowInfo(info);
    }
  }

  useEffect(() => {
    pageViewFacebook();   

    setNombre_usuairo(prev => searchParams.get("nombre") ?? prev);
    setTelefono(prev => searchParams.get("tel") ?? prev);
    setCorreo_usuario(prev => searchParams.get("correo") ?? prev);
    const info = Boolean(searchParams.get("info") ?? showInfo);

    const idFactura = searchParams.get("id_fact");

    console.log("info:",info);

    let load = 0;
    let toLoad = idFactura? 1: 0;
    if(info === true){
      setLoading(true);
      if(idFactura) getFactura(idFactura).then(res => {

        if(res){
          setFacturaPagoDirecto({id:idFactura, factura:res});
          setOpenPopover(true);
        }

        CargaCompleta(load > toLoad, info); console.log("toLoad:", toLoad);
        load++;
        console.log("load:", load);
      })
    }
    consultarEntrenamientoVentas(titleEntrenamiento ?? '').then(
      res => {
        if(!res){
          console.error("error cargando informacion del entrenamiento, entrenamiento no existente");
          if(ruta) navigate(`../${ruta}/neurobics${codigoParams? `/${codigoParams}`: ""}?${searchParams.toString()}`);
          else navigate("../");
        }else{
          console.log(res);
          CargaCompleta(load > toLoad, info); console.log("toLoad:", toLoad);
          load++;
          console.log("load:", load);
          setEntrenamiento(res);
        }
      }
    ).catch(e => {
      console.error(e, "error cargando informacion del entrenamiento");
      if (ruta) navigate(`../${ruta}/neurobics${codigoParams? `/${codigoParams}`: ""}?${searchParams.toString()}`);
      else navigate("../");
    })

    Consulta(codigoParams).then(() => {CargaCompleta(load > toLoad, info); console.log("toLoad:", toLoad); load++; console.log("load:", load);})
  },[location])

    
  const ValidarDatos = (): Boolean => {
    if((codigoParams === 'cod_3' || ValidarEmail(correo_usuario)) && codigoTel && telefono && nombre_usuairo ){
      return true
    }else{
      presentMensajePersonalizado("Datos incompletos")
      return false
    }
  }
    
  function HandleSubmit(): void {
    agregarAlCarroFacebook();
    if(ValidarDatos()){
      setOpenPopover(true);
      setMontar(true)
    }else{
      presentMensajePersonalizado("Datos incompletos")
    }
  }
  function ScrollToComprar(){
    const contenido = document.getElementById('inputInscripcion');
    if (contenido) contenido.scrollIntoView({block:'center',behavior:'smooth'});
  }

  const RegistrarCodigo = (codigo: string) => {
    setLoading(true);
    Consulta(codigo).finally(
      () => {
        setLoading(false);
        ScrollToComprar();
      }
    );
  }

  const Desplegar = (index:number) => {
    if(
      parrafo1.current &&
      parrafo2.current &&
      parrafo3.current &&
      parrafo4.current &&
      parrafo5.current &&
      parrafo6.current &&
      flecha1.current &&
      flecha2.current &&
      flecha3.current &&
      flecha4.current &&
      flecha5.current &&
      flecha6.current 
    ){
      switch(index){
        case 1:
          if(parrafo1.current.clientHeight !== 0){
            parrafo1.current.style.height = '0'
            parrafo3.current.style.overflow = 'hidden'
            flecha1.current.style.transform = 'rotate(180deg)'
          }else{
            parrafo1.current.style.height = 'fit-content'
            flecha1.current.style.transform = 'rotate(0deg)'
          }
          break
  
        case 2:
          if(parrafo2.current.clientHeight !== 0){
            parrafo2.current.style.height = '0'
            parrafo3.current.style.overflow = 'hidden'
            flecha2.current.style.transform = 'rotate(180deg)'
          }else{
            parrafo2.current.style.height = 'fit-content'
            flecha2.current.style.transform = 'rotate(0deg)'
          }
          break
  
        case 3:
          if(parrafo3.current.clientHeight !== 0){
            parrafo3.current.style.height = '0'
            parrafo3.current.style.overflow = 'hidden'
            flecha3.current.style.transform = 'rotate(180deg)'
          }else{
            parrafo3.current.style.height = 'fit-content'
            flecha3.current.style.transform = 'rotate(0deg)'
          }
          break
  
        case 4:
          if(parrafo4.current.clientHeight !== 0){
            parrafo4.current.style.height = '0'
            parrafo4.current.style.overflow = 'hidden'
            flecha4.current.style.transform = 'rotate(180deg)'
          }else{
            parrafo4.current.style.height = 'fit-content'
            flecha4.current.style.transform = 'rotate(0deg)'
          }
          break
          
        case 5:
          if(parrafo5.current.clientHeight !== 0){
            parrafo5.current.style.height = '0'
            parrafo5.current.style.overflow = 'hidden'
            flecha5.current.style.transform = 'rotate(180deg)'
          }else{
            parrafo5.current.style.height = 'fit-content'
            flecha5.current.style.transform = 'rotate(0deg)'
          }
          break
  
        default:
          if(parrafo6.current.clientHeight !== 0){
            parrafo6.current.style.height = '0'
            parrafo6.current.style.overflow = 'hidden'
            flecha6.current.style.transform = 'rotate(180deg)'
          }else{
            parrafo6.current.style.height = 'fit-content'
            flecha6.current.style.transform = 'rotate(0deg)'
          }
          break
  
      }
    }
  }

  // const HandleCompraPrueba = async () => {
  //   if(ValidarDatos()){
  //       setLoading(true)
  //       try {
  //         const usuario: MensajeUsuario = {
  //           nombre: nombre_usuairo,
  //           telefono: telefono,
  //           codigo: codigoTel
  //         }
  //         // const id = await agregarCollection("usuarios_msg_prueba", usuario);

  //         presentMensajePersonalizado("Completado!, revisa tu telefono.",4)
  //         }catch(error){
  //           console.log("🚀 ~ file: PagoPage.tsx:105 ~ handleEfectivo ~ error:", error)
  //           presentMensajePersonalizado("Hay un error con tu solicitud.")
  //       }   
  //       setLoading(false)
  //     }else{
  //       presentMensajePersonalizado("Datos incompletos")
  //   }
  // }

  return (
    <div id='page' className={estiloLanding.page} style={{height:'100%',maxWidth:'70svh',zIndex:'10',position:'relative'}} ref={ionPage}>
        <Loading isOpen={loading}></Loading>
        <div id='content_default' style={openPopover ? {overflowX:'hidden',height:'100%',overflowY:'scroll',opacity:"0.4",borderRadius: '0'}:{overflowX:'hidden',height:'100%',overflowY:'scroll',borderRadius: '0', marginBottom: "0"}}  >
          <div  style={{ width:'100%',height:'fit-content',background:'linear-gradient(0deg, #D74286 0%, #45225F 100%)'}} >
            
            <div style={{display:'flex',flexDirection:'row',width:'100%',justifyContent:'center',alignItems:'center'}}>
              <p className={estiloJuegos.tituloJuegos} style={{marginBottom:'0.81rem',marginTop:'2.18rem',fontWeight:'900'}}>"{titleEntrenamiento}" </p>
            </div>  
            <div className='linea' style={{backgroundColor:'#FFFFFF',margin:'0 10.25%'}}></div>
            
            <p className={estiloLanding.text}>Biohacking cerebral <br /> atención. concentración. memoria</p>
            <div className={estiloJuegos.contenedorVideo} style={{display:'flex',justifyContent:'center'}} ref={video}>
            {videoUrl1 !== null && 
            <>
              <VideoConContador videoUrl={codigoParams !== 'cod_3' ? videoUrl1: undefined} ref={referenceReproductor} 
              poster={codigoParams !== 'cod_3'?'/landingVideoPoster.jpg':''} preload='auto' autoplay={true} 
              style_video={codigoParams === 'cod_3'?{maxHeight:'60svh',width:'auto',height: "100%",maxWidth:'100%', aspectRatio:"9/16"}:
              {width: "100%", height: "auto"}} 
              style_contain={codigoParams === 'cod_3'?
              {maxHeight:'fit-content',width:'fit-content',height:'60svh',margin:'5% 0',borderRadius:'25px',overflow:'hidden',boxShadow:'5px 0px 10px black'}:
              {width: "100%", height: "auto"}}>
                {codigoParams === 'cod_3' &&
                <>
                  <source src={videoUrl3}/>
                  <source src='https://firebasestorage.googleapis.com/v0/b/supercerebros-desarrollo.appspot.com/o/publico%2Fneurobics%2FCORTESIA_NEUROBICS.mp4?alt=media&token=3e0c349f-9721-457d-b21d-f6df5ebec62e'/>
                </>
                }
              </VideoConContador>
            </>}
            </div>
            
          </div>
              
          {(!loading) && <div className={estiloLanding.showInfo} style={{width:'100%'}}>
            {codigoParams !== 'cod_3' && 
            <>
              <h2 className={estiloLanding.tituloMorado} style={{marginTop:'5.68vh',marginBottom:'4.26vh'}}>Libera el Poder de Tu Cerebro con Neurobics</h2>
              <p className={estiloLanding.parrafoGris}>
                ¿Sientes que tu mente está en modo de espera mientras la vida exige más de ti? Es hora de cambiar el guion. Imagina un cerebro más ágil, una memoria más nítida y una capacidad de concentración que te permita absorber información como un superordenador. Con "Neurobics", esto no es solo un sueño: es tu nueva realidad.
              </p>
              <div className='linea' style={{backgroundColor:'#45225F',margin:'3px 10%'}}></div>
              
              <div className={estiloLanding.container_grid}>
                <div className={estiloLanding.columna1} ><img src='/icon/Mano_SuperCerebro.svg'></img></div>
                <p className={estiloLanding.parrafo2}>Entrenadores:<br></br>
                  <span style={{ fontWeight:'700', color:'#45225F'}}>David Cantor - Juan Duque</span>
                </p>
                <div className={estiloLanding.columna1}><img src='/icon/Mano_SuperCerebro.svg'></img></div>
                <p className={estiloLanding.parrafo2}>Participantes:<br></br>
                  <span style={{ fontWeight:'700', color:'#45225F'}}>1214</span>
                </p>
                <div className={estiloLanding.columna1}><img src='/icon/Mano_SuperCerebro.svg'></img></div>
                <p className={estiloLanding.parrafo2}>Última actualización:<br></br>
                  <span style={{ fontWeight:'700', color:'#45225F'}}>Mayo 2024</span>
                </p>
                <div className={estiloLanding.columna1}><img src='/icon/Mano_SuperCerebro.svg'></img></div>
                <p className={estiloLanding.parrafo2}>Precio<br></br>
                  <span style={{fontSize:'2.3em', fontWeight:'700', color:'#45225F', marginTop:'10px',textTransform:'uppercase'}}>${Number(costo).toLocaleString('es-CO')}<span style={{fontSize:'1.5rem'}}>{'COP'}</span></span>
                </p>
              </div>
              
              <div className={estiloLanding.contenedorPrecioDescuento}>
                <img style={{height:'25.47vh'}} src='/sc_nice.png'></img>
                
                {(aplicaDescuento || codigoParams === 'cod_3') && <div className={estiloLanding.descuento}>
                  {codigoParams === 'cod_3'? 
                  <>
                    <span style={{fontSize:'2svh'}}>
                      Por un tiempo
                      <br></br> Limitado
                    </span>
                  </>
                  :<>
                  <span> {Math.floor(descuento/Number(costo)*100)} %</span><br></br>descuento
                  </>}
                  </div>}
                <div className={estiloLanding.precioCurso} style={(aplicaDescuento || codigoParams === 'cod_3')?{}:{alignSelf:'center',justifyContent:'center',height:'fit-content',padding:'2% 5%'}}>
                  <p style={{lineHeight:'110%',textAlign:'end'}}>
                    {codigoParams === 'cod_3' ?
                      <span style={{color:'inherit',fontWeight:'600'}}>
                        ACCESO GRATIS
                      </span>
                    : <>
                    ${Number(costo - descuento).toLocaleString('es-CO')}<span style={{color:'inherit'}}>COP</span>
                    </>}
                    </p>
                </div>  
                
              </div>
              <br></br>
              {aplicaDescuento && <div>
                <div style={{display:'grid',gridTemplateColumns:'repeat(4,1fr)',gap:'2%',height:'9vh'}}>
                  <div className={estiloLanding.cajonTime}>
                    {tiempoRestante?.dias}
                  </div>
                  <div className={estiloLanding.cajonTime}>
                    {tiempoRestante?.horas}
                  </div>
                  <div className={estiloLanding.cajonTime}>
                    {tiempoRestante?.minutos}
                  </div>
                  <div className={estiloLanding.cajonTime}>
                    {tiempoRestante?.segundos}
                  </div>

                </div>
                <div style={{display:'grid',gridTemplateColumns:'repeat(4,1fr)',gap:'2%',height:'fit-content',marginTop:'1vh'}}>
                  <p className={estiloLanding.subtitleTime}>días</p>
                  <p className={estiloLanding.subtitleTime}>Horas</p>
                  <p className={estiloLanding.subtitleTime}>minutos</p>
                  <p className={estiloLanding.subtitleTime}>segundos</p>
                </div>
              </div>}
              
              <div className={estiloLanding.boxInput} id='inputInscripcion'ref={inputInscripcion}>
                <h2 className={estiloLanding.tituloInputBox}>¿A dónde deberíamos enviar el acceso al curso?</h2>
                <p className={estiloLanding.campoObligatorio}>Nombre Completo*</p>
                <input type='text' style={{width:'100%'}} placeholder='Ingrese su nombre' value={nombre_usuairo} onChange={(e) => setNombre_usuairo(e.target.value)} className='inputPersonalizado2'></input>
                <p className={estiloLanding.campoObligatorio}>número de telefono*</p>
                <div style={{display:'flex',flexDirection:'row',height:'4.97svh'}}>
                  <div style={{height:'100%'}}>
                    <Selector setSelection={setCodigoTel} selection={codigoTel} opciones={paises} showValues={true}/>
                  </div>
                  <input type='number' style={{width:'100%'}} placeholder='Ejemplo: ' value={telefono} onChange={(e) => setTelefono(e.target.value)} className='inputPersonalizado2'></input>
                </div>
                <p className={estiloLanding.campoObligatorio}>Correo electronico*</p>
                <input type='text' style={{width:'100%'}} placeholder='Ejemplo: example@xmpl.com' value={correo_usuario} onChange={(e) => setCorreo_usuario(e.target.value)} className='inputPersonalizado2'></input>
                <div style={{display: 'flex',flexDirection:'column',alignItems:'center',width:'100%',justifyContent:'center',marginTop:'2.5vh'}}>
                  <button className='pagar' onClick={()=> HandleSubmit()}>{codigoParams === 'cod_3'? 'Conseguir Prueba Gratis':'ingresa al curso'}</button>
                </div>
                { codigoParams === 'campana' && 
                <>
                  <p className={estiloLanding.campoObligatorio}>Codigo de campaña</p>
                  <input type='text' style={{width:'100%'}} placeholder='Ejemplo: codigo' value={inputCodigoCampaña} onChange={(e) => setInputCodigoCampaña(e.target.value)} className='inputPersonalizado2'></input>
                  <div style={{display: 'flex',flexDirection:'column',alignItems:'center',width:'100%',justifyContent:'center',marginTop:'2.5vh'}}>
                    <button className='pagar' onClick={() => RegistrarCodigo(inputCodigoCampaña)}>registra el codigo</button>
                  </div>
                </>}
              </div>
              <br></br>
              <p className={estiloLanding.parrafoGris}>
                Nuestro curso no es solo una serie de ejercicios; es una odisea hacia la cima de tu potencial mental. Cada actividad está diseñada para ser sencilla, 
                accesible y, sobre todo, transformadora. En solo minutos al día, puedes comenzar a experimentar una mente más lúcida y preparada para enfrentar cualquier desafío.
              </p>
              <h2 className={estiloLanding.tituloMorado} style={{fontSize:'4.26vh',textTransform:'none'}}>¿Qué incluye el curso?</h2>
              <div className={estiloLanding.container_grid} style={{gap:'2svh'}}>
                <div className={estiloLanding.columna1} ><img src='/icon/Mano_SuperCerebro.svg'></img></div>
                <p className={estiloLanding.parrafo2Resaltado}>29 CLASES EN VIDEO
                </p>
                <div className={estiloLanding.columna1}><img src='/icon/Mano_SuperCerebro.svg'></img></div>
                <p className={estiloLanding.parrafo2Resaltado}>ACCESO POR 365 DÍAS A TODOS LOS MATERIALES DEL CURSO
                </p>
                <div className={estiloLanding.columna1} style={{alignSelf:'flex-start'}}><img src='/icon/Mano_SuperCerebro.svg'></img></div>
                <p >
                  <span className={estiloLanding.parrafo2Resaltado}>  ASISTENTE PERSONALIZADO POR WHATSAPP:</span>
                  <br />
                  <span className={estiloLanding.parrafo2}>
                    Tendrás un compañero de entrenamiento cerebral en tu bolsillo, listo para recordarte, motivarte y mantener tu progreso.
                  </span>
                </p>
              </div>
            </>}
            {codigoParams === 'cod_3' && <ComponenteCortesia ref={inputInscripcion} setLoading={setLoading} setMensaje={setMensaje}></ComponenteCortesia>}
            <br></br>
            <img src='/distintosDispositivos.png' style={{width:'100%'}}></img>
            <div className='linea' style={{backgroundColor:'#45225F',margin:'3px 10%'}}></div>

            <br></br>
            <p style={{marginTop:'5%',marginBottom:'2%',fontSize:`${textSize * 2}px`,color:'#45225F',textAlign:'center',textTransform:'uppercase',fontWeight:'900'}}>testimonios</p>
            <div className={estiloJuegos.contenedorVideo} ref={video} style={{margin:'5.1vh 0',padding:'0 15.34%'}}>
              {videoUrl2 !== null && <VideoConContador videoUrl={videoUrl2} autoplay={false} style_video={{width:'100%'}}/>}
            </div>
            <div>
              <div className={estiloLanding.contenedorFoto}>
                <h1 className={estiloLanding.tituloSobreNosotros}>SOBRE NOSOTROS</h1>
                <img className={estiloLanding.juanDavid} src='/sobre_nosotros.png'></img>
              </div>
              <div className={estiloLanding.textoSobreNosotros}>
              Somos los representantes Colombianos y semifinalistas en el programa “SUPERCEREBROS” del canal internacional NATGEO, con más de <span> 12 años de experiencia</span>,
                  dedicados al aprendizaje y la enseñanza de la metodología MILAG, tiempo en el cual hemos sido reconocidos en procesos de formación y Neuro-entrenamiento en niños, 
                  jóvenes, adultos y adultos sabios.
              </div>
            </div>
            {codigoParams !== 'cod_3' &&
            <>
              <div className={estiloLanding.preguntasFrecuentes}>
              {/* Preguntas frecuentes */}
              <p className={estiloLanding.superOferta}>FAQ</p>
              <p className={estiloLanding.tituloPreguntasFrecuentes}>Preguntas Frecuentes</p>
              <div style={{width:'60%',margin:'0 20%',marginBottom:'2%',height:'2px',backgroundColor:'#45225F'}}></div>
              <div className={estiloLanding.preguntas}>
                <p className={estiloLanding.preguntaTexto} onClick={() => {Desplegar(1)}}>
                  1-¿Cuál es la duración total del curso de Neurobics?</p>
                <img src='/icon/flechaAbrirContenido.svg' onClick={() => {Desplegar(1)}}  ref={flecha1}></img>
                <p className={estiloLanding.respuestaTexto} ref={parrafo1}>
                  Nuestro curso de Neurobics tiene una duración de 29 días, con ejercicios diarios que toman un máximo de 1 minuto de tu tiempo.
                </p>
                
                <p className={estiloLanding.preguntaTexto}  onClick={() => {Desplegar(2)}}>
                  2-¿Necesito experiencia previa en ejercicios cerebrales para inscribirme?
                  </p>
                <img src='/icon/flechaAbrirContenido.svg' onClick={() => {Desplegar(2)}} ref={flecha2}></img>
                <p className={estiloLanding.respuestaTexto} ref={parrafo2}>
                  No se requiere experiencia previa. Nuestro curso está diseñado para todos, independientemente de su nivel de conocimiento.
                </p>
                
                <p className={estiloLanding.preguntaTexto} onClick={() => {Desplegar(3)}}>
                  3-¿Cómo puedo acceder al contenido del curso después de la inscripción?
                </p>
                <img src='/icon/flechaAbrirContenido.svg' onClick={() => {Desplegar(3)}} ref={flecha3}></img>
                <p className={estiloLanding.respuestaTexto} ref={parrafo3}>
                  Una vez te inscribas, recibirás acceso instantáneo a todo el contenido del curso a través de nuestra plataforma en línea. Puedes acceder desde cualquier dispositivo con conexión a internet.
                </p>
                
                <p className={estiloLanding.preguntaTexto} onClick={() => {Desplegar(4)}}>
                  4-¿Hay algún requisito técnico para tomar el curso?
                </p>
                <img src='/icon/flechaAbrirContenido.svg' onClick={() => {Desplegar(4)}} ref={flecha4}></img>
                <p className={estiloLanding.respuestaTexto} ref={parrafo4}>
                  Solo necesitas un dispositivo con acceso a internet y un navegador web actualizado para acceder al curso. No se requieren descargas ni software especial.
                </p>
              </div>
              </div>
              <div style={{backgroundColor:'#FFFFFF',borderRadius:'23px',height:'5vh',margin:'0 -5.5%',marginBottom:'-5%',position:'relative',zIndex:'2'}}></div>
              <div style={{marginTop:'0%',paddingTop:'10%',background:'linear-gradient(167deg, #45225F 7.98%, #D74286 79.34%)',height:'fit-content',width:'111%',margin:'0 -5.5%',position:'relative',bottom:'0',padding:'10% 5%',display:'flex',flexDirection:'column',alignItems:'center'}}>
                <p style={{marginTop:'0%',marginBottom:'0%',fontSize:`${textSize * 3}px`,color:'#FFFFFF',textAlign:'center',textTransform:'uppercase',fontWeight:'900',fontFamily:'Montserrat'}}>clientes</p>
                <p style={{marginTop:'2%',marginBottom:'2%',fontSize:`${textSize * 1}px`,color:'#FFFFFF',textAlign:'center',textTransform:'uppercase',fontWeight:'200',fontFamily:'Montserrat'}}>Algunos de nuestros clientes</p>
                <div style={{height:'20vh',width:'100%',backgroundColor:'#FFFFFF',marginTop:'5%',borderRadius:'20px',display:'grid',gridTemplateColumns:'1fr 1fr 1fr',alignItems:'center',justifyItems:'center',alignContent:'center'}}>
                  <img src='/uan.png'></img>
                  <img src='/compensar.png'></img>
                  <img src='/planetario_sticker.png'></img>
                  <img src='/bancoOccidente.png'></img>
                  <img src='/gimnasio.png'></img>
                </div>
                <div style={{background:'#FFFFFF',width:'100%',height:'1px',margin:'10% 0'}}></div>
                <p className={estiloLanding.text_info} style={{fontSize:`${textSize}px`}}>SuperCerebros SAS<br></br>Calle 19 #6-68 Oficina 603, Bogotá<br></br>310 311 3156</p>
              </div>
            </>}
          </div>}
          <button
           className={estiloLanding.buttonEstatico} onClick={ScrollToComprar}>
            INGRESA AL CURSO
          </button>
        </div>
        
        {openPopover &&
        <>
          <div className={[stylePopover.contenedor,montar ? '' : stylePopover.desmontar].join(" ")}>
            <div className={stylePopover.contenido}>
              <div className="contenido_popover" >

                <h3 className='confirmacion_pedido'>CONFIRMACIÓN DE PEDIDO</h3>
                <div className='producto'>
                  <div> {`Entrenamiento "${entrenamiento?.titulo}"`}</div>
                  <div style={{textAlign:"end"}}>${(costo).toLocaleString('es-CO')}</div>
                </div>
                {aplicaDescuento &&<div  className='producto'>
                  <div>Descuento</div>
                  <div style={{textAlign:"end"}}>${(-descuento).toLocaleString('es-CO')} </div>
                </div>}
                <div className='linea_punteada'></div>
                <div className='valor_total'>
                  <div>Valor total:</div>
                  {/* <div style={{textAlign:"end"}}>${(costo - costo * descuento).toLocaleString('es-CO')} </div> */}
                  <div style={{textAlign:"end"}}>${(costo - descuento).toLocaleString('es-CO')} </div>
                </div>
                
                <Checkout titulo={`Entrenamiento ${entrenamiento?.titulo}`} 
                  description={`Entrenamiento ${entrenamiento?.titulo}`} 
                  costo={(costo - descuento)}
                  setLoading={CambiarLoading}
                  userId={''}
                  id_productos={[{id_referencia:titleEntrenamiento,tipo:'entrenamiento'}as ProductoReferencia]}
                  rutaRedireccion='/responseFactura'
                  email={correo_usuario}
                  telefono={telefono}
                  codigoTelefono={codigoTel}
                  nombre={nombre_usuairo}
                  campaña={campaña?.id ? campaña.id: ''}
                  pagoDirecto={facturaPagoDirecto}
                  sesionesPrueba={campaña?.sesionesPrueba}
                  urlCompra={location.pathname}
                  />
                <button style={{height:"4.5vh", width:"100%",marginBottom:"2vh"}} className="volver" onClick={DesmontarPopover} >
                  VOLVER
                </button>
              </div>
            </div>
          </div>
        </>}     
        
        {mensaje && <Modal open={mensaje} onClickOutside={() => setMensaje(false)}>
            <div className={estiloLanding.modalMensaje} >
                Revisa tu whatsapp
            </div>
        </Modal>}
    </div>
  )
}

export default LandingCurso