// /features/juegoFechas/utils.ts
  export const getRandomDateIn2024 = (start:Date,end:Date): Date => {
    const randomTime = start.getTime() + Math.random() * (end.getTime() - start.getTime());
    return new Date(randomTime);
  };
  
  export const obtenerDiaDeLaSemana = (fecha: Date): number => {
    return fecha.getDay();
  };
  
